<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog v-model="dialogFull" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogFull = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ tituloAnalisis }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2" dark icon v-on="on" @click="exportExcel">
                    <v-icon dark> mdi-file-export </v-icon>
                  </v-btn>
                </template>
                <span>Exportar a CSV</span>
              </v-tooltip>
            </v-toolbar>
            <v-row class="mx-2">
              <v-col cols="12">
                <h3>
                  Contexto:
                  {{ selectTipo == "E" ? "Emitido" : "Recibido" }}
                  <span class="mx-10"></span> Periodo:
                  {{ selectPeriodo }}
                </h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-title>Monto de Impuestos Locales Trasladados
                  </v-card-title>
                  <v-card-subtitle>{{
                    currencyFormat(this.total_traslados)
                  }}</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-title>Monto de Impuestos Locales Retenidos
                  </v-card-title>
                  <v-card-subtitle>{{
                    currencyFormat(this.total_retenidos)
                  }}</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col v-if="traslados.length > 0" cols="12" md="6">
                <v-card class="mx-auto pa-2 ma-2" min-height="550">
                  <p class="display-1 text--primary">Traslados</p>
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-right">
                            {{
                              selectTipo == "E"
                                ? "Importe Efectivamente Cobrado"
                                : "Importe Efectivamente Pagado"
                            }}
                          </th>
                          <th>Impuesto</th>
                          <th>Tipo Factor</th>
                          <th class="text-right">Tasa o Cuota</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in traslados" :key="item.id" class="altura">
                          <td align="right">
                            {{ currencyFormat(item.E) }}
                          </td>
                          <td>
                            {{ getImpuesto(item.Impuesto) }}
                          </td>
                          <td>
                            {{ item.TipoFactor }}
                          </td>
                          <td align="right">
                            {{ taxFormat(item.TasaOCuota) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col v-if="retenciones.length > 0" cols="12" md="6">
                <v-card class="mx-auto pa-2 ma-2" min-height="550">
                  <p class="display-1 text--primary">Retenciones</p>
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-right">
                            {{
                              selectTipo == "E"
                                ? "Importe Efectivamente Cobrado"
                                : "Importe Efectivamente Pagado"
                            }}
                          </th>
                          <th>Impuesto</th>
                          <th>Tipo Factor</th>
                          <th class="text-right">Tasa o Cuota</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in retenciones" :key="item.id" class="altura">
                          <td align="right">
                            {{ currencyFormat(item.E) }}
                          </td>
                          <td>
                            {{ getImpuesto(item.Impuesto) }}
                          </td>
                          <td>
                            {{ item.TipoFactor }}
                          </td>
                          <td align="right">
                            {{ taxFormat(item.TasaOCuota) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Análisis</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field v-model="newName" counter="0" :rules="nameRules" maxlength="50" label="*Nombre"
                    class="my-1" max="50"></v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                  <v-text-field v-model="newDescription" counter="0" :rules="desRules" maxlength="200"
                    label="Descripción" class="my-1" max="200"></v-text-field>
                </v-col>
                <v-col class="mx-2" cols="12" md="4" justify="center">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="newDate" type="month" label="*Período" prepend-icon="mdi-calendar-month"
                        readonly v-bind="attrs" v-on="on" locale="es"></v-text-field>
                    </template>
                    <v-date-picker min="2020/12" v-model="newDate" locale="es" type="month"
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="['Emitidos', 'Recibidos']" v-model="newOrigin" label="*Origen" outlined></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text large v-on:click="SolicitarAnalisis" :disabled="!showCreateButton">Crear
                Análisis</v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Análisis:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este análisis sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12" md="11">
          <h3>Impuestos Efectivamente Cobrados/Pagados

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/impuestos-efectivamente-cobrados-pagados"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>

        <v-col cols="12" class="text-justify">
          Este análisis se solicita por mes. Dicho análisis se realiza para
          todos los comprobantes emitidos en el mes y sus correspondientes
          comprobantes de pago (estos últimos solo los que tienen fecha de
          emisión del mes solicitado o con fecha en los primeros cinco días del
          siguiente mes). Debido a que solo se suman los impuestos efectivamente
          pagados o cobrados, los comprobantes PUE se consideran pagados en la
          misma fecha de emisión del comprobante. Los comprobantes PPD se
          consideran pagados cuando se emita un comprobante de pago. Es el caso
          de los comprobantes PPD que sean pagados en parcialidades, el sistema
          calcula la proporción que se paga para cada tipo de impuesto, en base
          a la proporción que representa ese impuesto específicamente en esa
          factura.
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col cols="12" md="1" lg="1" class="pa-20 ma-10">
                <v-btn color="primary" white large @click="showDialogCrear = true">Crear Análisis</v-btn>
              </v-col>
              <v-col cols="12" md="1" lg="1" class="hidden-sm-and-down"></v-col>
              <v-col cols="12" md="1" lg="1" class=" pa-20 ma-10">
                <v-btn color="primary" white large v-on:click="ObtenerAnalisis">Actualizar Listado</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12"></v-col>
        <v-col v-if="items.length > 0" cols="12">
          <v-card>
            <v-simple-table height="600" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Fecha Solicitud</th>
                    <th>Contexto</th>
                    <th>Periodo Solicitado</th>
                    <th>Mensaje</th>
                    <th>Disponible</th>
                    <th>Resultado</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id" class="altura">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.requestDate }}
                    </td>
                    <td>
                      {{ item.commands.origin == "E" ? "Emitido" : "Recibido" }}
                    </td>
                    <td>
                      {{
                        item.commands.dateYear +
                        "-" +
                        pad(item.commands.dateMonth, 2)
                      }}
                    </td>
                    <td>
                      {{ item.message }}
                    </td>
                    <td>
                      {{ item.ready ? "Si" : "No" }}
                    </td>
                    <td>
                      {{
                        item.ready
                          ? item.result
                            ? "Exitoso"
                            : "Fracasado"
                          : "Procesando"
                      }}
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left v-if="item.ready && item.result">
                        <template v-slot:activator="{ on }">
                          <v-btn @click="
                            MostrarAnalisis(
                              item.id,
                              item.name + ' - ' + item.description,
                              item.commands
                            )
                            " icon color="#4aa832" v-on="on">
                            <v-icon>mdi-eye-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver Análisis</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left v-if="item.ready">
                        <template v-slot:activator="{ on }">
                          <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar Análisis</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ImpuestosPagadosCobrados",
  data: () => ({




    date: new Date().toISOString().substr(0, 7),
    menu: false,
    menu2: false,
    contextDoc: "Seleccionar Todos",
    contextItems: ["Seleccionar Todos", "Emitidos", "Recibidos"],
    retenciones: [],
    traslados: [],
    showDialogCrear: false,
    boolItems: ["Seleccionar Todos", "Si", "No"],
    okItems: ["Seleccionar Todos", "Exitoso", "Fracasado"],
    okDoc: "Seleccionar Todos",
    readyDoc: "Seleccionar Todos",
    items: [],
    itemsResult: [],
    dialogFull: false,
    tituloAnalisis: "",
    selectTipo: "",
    selectPeriodo: "",
    newName: "",
    newDescription: "",
    newDate: "",
    newOrigin: "",
    nameRules: [(v) => v.length <= 50 || "Maximo 50 letras"],
    desRules: [(v) => v.length <= 200 || "maximo 200 letras"],
    impuestosLocalTraslados: 0,
    impuestosLocalRetenidos: 0,
    impuestosLocales: null,
    total_retenidos: 0,
    total_traslados: 0,
    itemDesc: "",

    showDialogEliminarReport: false,
    tipoImp: ""
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
    this.ObtenerAnalisis();

  },
  methods: {
    pad(input, length) {
      return Array(length - Math.floor(Math.log10(input))).join("0") + input;
    },
    limpiarCrear() {
      this.newName = "";
      this.newDescription = "";
      this.newDate = "";
      this.newDate = "";
      this.newOrigin = "";
    },
    ObtenerAnalisis() {
      var jsonData = {
        token: this.$store.state.token
      };

      service
        .api()
        .RetrieveTaxAnalysis2(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.items = JSON.parse(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    MostrarAnalisis(idAnalisis, titulo, commands) {
      this.tituloAnalisis = titulo;
      this.selectTipo = commands.origin;
      this.selectPeriodo =
        commands.dateYear + "-" + this.pad(commands.dateMonth, 2);
      var jsonData = {
        token: this.$store.state.token,
        id: idAnalisis,
      };

      service
        .api()
        .RetrieveTaxAnalysisResults(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsResult = JSON.parse(response.payload);
            this.traslados = this.itemsResult.filter(
              (tras) => tras.Tipo === "TRASLADO"
            );
            this.retenciones = this.itemsResult.filter(
              (ret) => ret.Tipo === "RETENCION"
            );
            this.impuestosLocales = this.itemsResult.filter(
              (imp) => imp.Tipo === "IMPUESTOS-LOCALES"
            );

            if (this.impuestosLocales.length === 0) {
              this.total_retenidos = 0;
              this.total_traslados = 0;
            } else {
              this.total_retenidos = this.impuestosLocales[0].total_retenidos;
              this.total_traslados = this.impuestosLocales[0].total_traslados;
            }

            if (this.traslados.length === 0 && this.retenciones.length === 0) {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No se encontraron traslados ni retenciones");
            } else this.dialogFull = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    SolicitarAnalisis() {
      var jsonData = {
        token: this.$store.state.token,
        name: this.newName,
        description: this.newDescription,
        dateYear: this.newDate.substr(0, 4),
        dateMonth: this.newDate.substr(5, 2),
        origin: this.newOrigin.substr(0, 1),
      };

      service
        .api()
        .RequestTaxAnalysis(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);

            this.showDialogCrear = false;
            this.limpiarCrear();

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");


          } else {

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    exportExcel() {
      var headers = {
        E: "Importe",
        Impuesto: "Impuesto",
        Tasa: "Tasa o Cuota",
        Tipo: "Contexto",
        Factor: "Tipo Factor",
      };
      var itemsFormatted = [];
      if (this.itemsResult === null) return;

      this.itemsResult = [...this.traslados, ...this.retenciones];

      this.itemsResult.forEach((item) => {
        itemsFormatted.push({
          E: helpers.ConvertToEmpty(item.E),
          Impuesto: helpers.Impuesto(helpers.ConvertToEmpty(item.Impuesto)),
          Tasa: helpers.ConvertToEmpty(item.TasaOCuota),
          Tipo: helpers.ConvertToEmpty(item.Tipo),
          Factor: helpers.ConvertToEmpty(item.TipoFactor),
        });
      });

      itemsFormatted.push({
        E: this.impuestosLocales[0].total_traslados,
        Impuesto: "IMPUESTO LOCAL TRASLADADO",
        Tasa: " ",
        Tipo: " ",
        Factor: " ",
      });

      itemsFormatted.push({
        E: this.impuestosLocales[0].total_retenidos,
        Impuesto: "IMPUESTO LOCAL RETENIDO",
        Tasa: " ",
        Tipo: " ",
        Factor: " ",
      });

      var fileTitle =
        "Impuestos_EfectivamentePagados/Cobrados_Contexto_" +
        (this.selectTipo == "E" ? "Emitido" : "Recibido") +
        "_Periodo_" +
        this.selectPeriodo +
        "_Solicitado_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    taxFormat(value) {
      return helpers.formatTax(value);
    },
    getImpuesto(value) {
      return helpers.Impuesto(helpers.ConvertToEmpty(value));
    },
    getContexto() {
      let firstLetter = this.contextDoc.substr(0, 1);
      return firstLetter == "S" ? "" : firstLetter;
    },
    getBool(selBool) {
      let booleanVal = "";
      switch (selBool) {
        case "Si":
          booleanVal = "1";
          break;
        case "No":
          booleanVal = "0";
          break;
        default:
          booleanVal = "";
      }
      return booleanVal;
    },
    getOkBool(selBool) {
      let booleanVal = "";
      switch (selBool) {
        case "Exito":
          booleanVal = "1";
          break;
        case "Fracaso":
          booleanVal = "0";
          break;
        default:
          booleanVal = "";
      }
      return booleanVal;
    },

    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.name + ' ' + item.description;
      this.tipoImp = this.ExtraerParticion(item.particion);
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        analysisType: this.tipoImp
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteTaxAnalysis2(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Análisis Eliminado Correctamente");
            this.ObtenerAnalisis();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    ExtraerParticion(cadena) {
      const partes = cadena.split("-");
      if (partes.length >= 2) {
        return partes.slice(1).join("-");
      }
      return ""; 
    }
  },
  computed: {
    showCreateButton() {
      return (
        this.newName.length > 0 &&
        this.newOrigin.length > 0 &&
        this.newDate.length > 0
      );
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}
</style>
